import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AccountContext } from '../../../contextApi/AccountContext';
import { APIs } from '../../../config';
import WorkspaceCard from './WorkspaceCard';
import PendingInvitations from './PendingInvitations';
import LoadingPageFull from '../../../components/LoadingPageFull';
import PopupModal from '../../../components/PopupModal';
import { ReactComponent as PlusSVG } from '../../../assets/icons/PlusIcon.svg';
import styled from 'styled-components';
import GenAILogo from '../../../assets/logo/GenAI.svg';

export default function SelectWorkspace() {
  const navigate = useNavigate();
  const { getSession, sessionJWT, userStatus } = useContext(AccountContext);
  const [workspaceData, setWorkspaceData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [popupModalStatus, setPopupModalStatus] = useState(false);

  const openAuthenticatePopup = () => {
    const goToSignIn = () => navigate('/signin');
    PopupModal.closePopupModal = () => goToSignIn();
    PopupModal.popupMessage = 'Please sign in first';
    PopupModal.handleConfirmAction = () => goToSignIn();
    setPopupModalStatus(true);
  };

  const openCautionPopup = ({ message, confirmOption }) => {
    const goToSignIn = () => navigate('/');
    const confirmAction = () => {
      if (confirmOption === 'stay') {
        return () => {
          setPopupModalStatus(false);
        };
      } else if (confirmOption === 'goToSignIn') {
        return () => {
          setPopupModalStatus(false);
          goToSignIn();
        };
      } else {
        return confirmOption;
      }
    };
    PopupModal.closePopupModal = () => setPopupModalStatus(false);
    PopupModal.popupMessage = message || 'Please sign in first';
    PopupModal.handleConfirmAction = confirmAction;
    setPopupModalStatus(true);
  };

  const clearData = () => {
    [
      'create',
      'keyword',
      'location',
      'facebookAds',
      'googleAds',
      'googleAnalytics',
    ].forEach((data) => localStorage.removeItem(data));
  };

  const accessCode = () => {
    clearData();
    navigate('/accesscode');
  };

  const getWorkspaceData = async () => {
    try {
      const request = await fetch(`${APIs('workspaces')}`, {
        headers: {
          Authorization: `Bearer ${sessionJWT}`,
          'Content-Type': 'application/json',
        },
      });
      const response = await request.json();
      if (request.ok) {
        setWorkspaceData(response);
      } else if (request.status !== 500) {
        openCautionPopup({ message: response.detail, confirmOption: 'stay' });
      } else {
        openCautionPopup({
          message: response.detail,
          confirmOption: 'goToSignIn',
        });
      }
    } catch (err) {
      console.error(err);
      openCautionPopup({
        message: err,
        confirmOption: 'goToSignIn',
      });
    }
  };

  useEffect(() => {
    getSession();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (sessionJWT) {
      getWorkspaceData();
    } else return;
  }, [sessionJWT]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      sessionJWT &&
      workspaceData?.detail === 'Incorrect authentication credentials.'
    ) {
      openAuthenticatePopup();
    } else if (userStatus.userNotSignedIn) {
      openAuthenticatePopup();
    } else if (
      sessionJWT &&
      workspaceData?.workspaces?.length +
        workspaceData?.invitedWorkspaces?.length >
        0
    ) {
      setIsLoading(false);
    } else if (
      sessionJWT &&
      workspaceData?.workspaces?.length +
        workspaceData?.invitedWorkspaces?.length ===
        0
    ) {
      return accessCode();
    }
  }, [workspaceData]); // eslint-disable-line react-hooks/exhaustive-deps

  if (isLoading)
    return (
      <>
        <LoadingPageFull />
        <PopupModal
          popupModalStatus={popupModalStatus}
          closePopupModal={PopupModal.closePopupModal}
          popupMessage={PopupModal.popupMessage}
          handleConfirmAction={PopupModal.handleConfirmAction}
        />
      </>
    );
  return (
    <>
      <Container>
        <TitleBox>
          <PlaybookIcon src={GenAILogo} />
          <Title>Choose Workspace!</Title>
          <SubTitle>
            Welcome back to Swen AI! <br />
            Please choose or create your workspace.
          </SubTitle>
        </TitleBox>
        {workspaceData?.invitedWorkspaces?.length > 0 && (
          <PendingInvitations
            invitedWorkspaces={workspaceData?.invitedWorkspaces}
            openCautionPopup={openCautionPopup}
          />
        )}
        <WorkspaceContainer>
          {workspaceData?.workspaces?.map((workspaceInfo, idx) => {
            return <WorkspaceCard key={idx} workspaceInfo={workspaceInfo} />;
          })}

          <CreateWorkspace onClick={() => accessCode()}>
            <CreateTitle>Create</CreateTitle>
            <PlusIconBox>
              <Plus />
            </PlusIconBox>
          </CreateWorkspace>
        </WorkspaceContainer>
      </Container>
      <PopupModal
        popupModalStatus={popupModalStatus}
        closePopupModal={PopupModal.closePopupModal}
        popupMessage={PopupModal.popupMessage}
        handleConfirmAction={PopupModal.handleConfirmAction}
      />
    </>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 12vh;
  margin-bottom: 12vh;
  overflow-y: scroll;
  padding-bottom: 5vh;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PlaybookIcon = styled.img`
  width: 75px;
  height: 75px;
  margin-bottom: 37px;
`;

const Title = styled.h1`
  font-size: 48px;
  color: black;
  font-weight: 300;
  margin-bottom: 16px;
`;

const SubTitle = styled.h2`
  font-size: 16px;
  font-weight: 400;
  color: #000f1f;
  margin-bottom: 91px;
  text-align: center;
`;

const WorkspaceContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 830px;
`;

const CreateWorkspace = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 200px;
  margin: 4px 4px 4px 0;
  background-color: #ffffff;
  border-radius: 8px;
  border: 1px solid #f5f5f5;
  box-shadow: 0px 1px 3px 0px #09103766;
  cursor: pointer;
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0px 3px 7px rgba(9, 16, 55, 0.15);
    background-color: ${({ theme }) => theme.pb.lightGray};
    transition: 0.25s;
  }
`;

const CreateTitle = styled.p`
  font-size: 18px;
  color: #000000;
  font-weight: 400;
  margin-bottom: 18px;
`;

const PlusIconBox = styled.div`
  width: 90px;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Plus = styled(PlusSVG)`
  stroke: #194384;
`;
