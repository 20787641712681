import React, { useState, useEffect, useContext } from 'react';
import { useMediaQuery } from 'react-responsive';
import { LanguageProvider } from '../../contextApi/LanguageContext';
import { AccountContext } from '../../contextApi/AccountContext';

import PanomixNav from '../../components/Nav/PanomixNav';
import PanomixMobileNav from '../../components/Nav/PanomixMobileNav';
import Footer from '../../components/Footer/Footer';
import { useNavigate } from 'react-router-dom';

import CookiePolicy from './TermsAndPrivacy/CookiePolicy';
import Terms from './TermsAndPrivacy/Terms';
import PrivacyPolicy from './TermsAndPrivacy/PrivacyPolicy';

import styled from 'styled-components';
import GenAILogo from '../../assets/logo/GenAI.svg';

export default function Main({ type }) {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({
    maxWidth: 800,
  });

  const PAGES = {
    cookies: <CookiePolicy />,
    terms: <Terms />,
    privacy: <PrivacyPolicy />,
  };

  const [nextStep, setNextStep] = useState(false);
  const { sessionJWT, getSession } = useContext(AccountContext);

  useEffect(() => {
    if (sessionJWT && sessionJWT !== '') {
      setNextStep(true);
    } else {
      getSession();
    }
  }, [sessionJWT]); // eslint-disable-line react-hooks/exhaustive-deps

  // Check sessionJWT exists. If not,
  useEffect(() => {
    getSession();
  });

  useEffect(() => {
    nextStep === true && navigate('/workspacelist');
    // Decide where to go here? if workspace not found then onboarding pages.
  }, [nextStep]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <LanguageProvider>
      <Wrapper>
        {isMobile ? <PanomixMobileNav /> : <PanomixNav />}
        <TitleBox>
          <PlaybookIcon src={GenAILogo} />
          <Title>Welcome to Swen AI</Title>
          <SubTitle>
            Swen AI is an LLM-based news generation platform. Publish News
            Articles in minutes with Swen AI!
          </SubTitle>
        </TitleBox>
        <Footer />
      </Wrapper>
      {type && (
        <TermsAndPrivacyWrapper>
          <AlignBox needMarginTop={true}>{PAGES[type]}</AlignBox>
          <Footer />
        </TermsAndPrivacyWrapper>
      )}
    </LanguageProvider>
  );
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  background-size: cover;

  width: 100vw;
  height: 100vh;
`;

const TermsAndPrivacyWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: black;

  width: 100vw;
  height: auto;
`;

const AlignBox = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1300px;
  margin-top: ${({ needMarginTop }) => needMarginTop && '80px'};
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PlaybookIcon = styled.img`
  width: 75px;
  height: 75px;
  margin-bottom: 37px;
`;

const Title = styled.h1`
  font-size: 48px;
  color: white;
  font-weight: 400;
  margin-bottom: 24px;
  font-family: Poppins;
`;

const SubTitle = styled.h2`
  font-size: 18px;
  font-weight: 400;
  color: white;
  margin-bottom: 80px;
  text-align: center;
  width: 450px;
  line-height: 28px;
  font-family: Arial;
`;
